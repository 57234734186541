import React from "react";

import classNames from "classnames";

const Copy = ({className, children, ...props}) => {
  return (
    <div 
      className={classNames(
        "prose max-w-none",
        "prose-black",
        "prose-headings:font-normal",
        "prose-li:m-0",
        "prose-td:text-base",
        className)}
      {...props}
    >
      {children}
    </div>
  );
};

export {
  Copy,
};
