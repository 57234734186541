import React from "react";
import classNames from "classnames";

const Button = ({as: Component = "button", className, children, ...props}) => {
  return (
    <Component {...props}
      className={classNames(
        "inline-block uppercase p-3 bg-gray-500 hover:bg-gray-600 transition-colors duration-300 text-white",
        className)}
      >{children}
    </Component>
  );
};

export {
  Button,
};
