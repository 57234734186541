import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const GridLink = ({className, children, ...props}) => {
  return (
    <Link className={classNames(className, "kgh-grid-link group relative")} {...props}>
      {children}
    </Link>
  );
};

const GridLinkOverLay = ({as: Component = "div", className, children, ...props}) => {
  return (
    <Component className={classNames(className, "absolute inset-x-6 top-1/2 text-center bg-gray-500/75 sm:bg-gray-500/50 group-hover:bg-gray-500/75 text-white p-4 transition-colors duration-300 truncate")} {...props}>
      {children}
    </Component>
  );
};

export {
  GridLink,
  GridLinkOverLay,
};
