import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

import { useStaticQuery, graphql, Link } from "gatsby";
import { Heading, Section } from "./Page";

const TestimonialsSection = () => {
  const { allMarkdownRemark: { edges } } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fields: {collection: {eq: "testimonials"}}, frontmatter: {homePage: {ne: null}}}
        sort: {fields: frontmatter___homePage}
      ) {
        edges {
          node {
            id
            frontmatter {
              homePage
              authors
            }
            html
          }
        }
      }
    }`);
  return (
    <Section>
      <Heading>Testimonials</Heading>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-4">
        {edges
          .map(({node: {id, frontmatter: {authors}, html}}) => ({key: id, authors, content: html}))
          .map(props => <Testimonial {...props} />)}
      </div>  
      <div className="text-center my-10" >
        <Link className="underline" to="/testimonials">See more testimonials&hellip;</Link>
      </div>
    </Section>
  );
};

const Testimonial = ({ content, authors }) => {
  return (
    <div className="text-center">
      <div className="mb-4 text-gray-600">
        <FontAwesomeIcon className="text-4xl" icon={faQuoteLeft} fixedWidth />
      </div>
      <div className="grid grid-cols-1 divide-y">
        <div className="italic pb-4 text-gray-600" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="pt-4">
          {authors}
        </div>
      </div>
    </div>
  );
};

export {
  TestimonialsSection,
};
