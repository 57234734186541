import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Button } from "../components/Button";
import { Carousel } from "../components/Carousel";
import { Copy } from "../components/Copy";
import { Layout } from "../components/Layout";
import { TestimonialsSection } from "../components/TestimonialsSection";
import { GridLink, GridLinkOverLay } from "../components/GridLink";
import { useSiteMetadata } from "../hooks/SiteMetadataQuery";
import { Container, Heading, Section } from "../components/Page";

const carouselImageProps = {
  width: 1400,
};

const gridImageProps = {
  width: 600,
};

const IndexPage = () => {
  const { getAQuoteUrl } = useSiteMetadata();
  return (
    <Layout>
      <Carousel items={[
        {
          to: "/weddings/house-collection/eucalyptus-wreath",
          image: <StaticImage
            {...carouselImageProps}
            src="../house-collection/eucalyptus-wreath/Eucalyptus wreath invite-7.jpg"
            alt="Eucalyptus wreath invite"
          />
        },
        {
          to: "/weddings/house-collection/blossom-tree",
          image: <StaticImage
            {...carouselImageProps}
            src="../house-collection/blossom-tree/Custom Blossom Tree Invite-11.jpg"
            alt="Blossom tree invite"
          />
        },
        {
          to: "/weddings/house-collection/watercolour-fade",
          image: <StaticImage
            {...carouselImageProps}
            src="../house-collection/watercolour-fade/Watercolour fade-10.jpg"
            alt="Watercolour fade invite"
          />
        },
        {
          to: "/weddings/house-collection/alpaca",
          image: <StaticImage
            {...carouselImageProps}
            src="../house-collection/alpaca/Alpaca Invite-02.jpg"
            alt="Alpaca invite"
          />
        },
        {
          to: "/weddings/house-collection/pampas-grass",
          image: <StaticImage
            {...carouselImageProps}
            src="../house-collection/pampas-grass/Pampas Grass-10.jpg"
            alt="Pampas grass invite"
          />
        },
      ]} />
      <Container>
        <Section className="max-w-narrow">
          <Heading>KGH Illustrates</Heading>
          <div className="text-center my-6">
            <StaticImage imgClassName="rounded-full" height="200" width="200" src="../images/about/Me.jpg" />
          </div>
          <Copy>
            <p>
              Welcome to KGH Illustrates, a small business based in Norfolk and is ran solely by Kimberley.
              She will work closely with you to create the perfect luxury wedding stationery, printed onto premium card stocks with a choice finishing touches.
            </p>
            <p>
              You can choose from pre-designed invitations in the House Collection or Kimberley can handpaint something bespoke, designed just for you!
            </p>
          </Copy>
          <div className="text-center">
            <Button
              as="a"
              className="text-lg lg:text-2xl p-5 my-8"
              rel="noreferrer"
              target="_blank"
              href={getAQuoteUrl}
            >
              Get a quote
            </Button>
          </div>
        </Section>
        <Section className="grid gird-cols-1 lg:grid-cols-3 gap-4">
          <GridLink to="/weddings/house-collection" className="h-[300px]">
            <StaticImage
              {...gridImageProps}
              src="../house-collection/agate/Agate Invite-09.jpg"
              alt="House collection invite" />
            <GridLinkOverLay as="h1" className="text-lg md:text-2xl">House Collection</GridLinkOverLay>
          </GridLink>
          <GridLink to="/weddings/custom" className="h-[300px]">
            <StaticImage
              {...gridImageProps}
              src="../images/custom/St Peters Church Walpole venue invite-09.jpg"
              alt="Bespoke church venue invite" />
            <GridLinkOverLay as="h1" className="text-lg md:text-2xl" >Custom Designs</GridLinkOverLay>
          </GridLink>
          <GridLink to="/weddings/on-the-day" className="h-[300px]">
            <StaticImage
              {...gridImageProps}
              src="../images/order-of-the-day/Blossom Order of the Day.jpg"
              alt="Blossom tree order of the day sign" />
            <GridLinkOverLay as="h1" className="text-lg md:text-2xl">On The Day</GridLinkOverLay>
          </GridLink>
        </Section>
        <TestimonialsSection />
      </Container>
    </Layout>
  );
};

export default IndexPage;
